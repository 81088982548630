.main {
  display: flex;
  gap: 10%;
  flex-wrap: wrap;
  padding: 15vh 7.5vw;
}

.info, .info>h2, .info>p {
  background-color: transparent;
}

.info {
  flex-basis: 40%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  z-index: 3;
}

.info>h2 {
  font-size: 36px;
  letter-spacing: 1px;
}

.info>p {
  font-size: 18px;
  letter-spacing: 1px;
  font-family: "Mulish", sans-serif;
  line-height: 26px;
  -webkit-font-smoothing: antialiased;
}

.back {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  position: relative;
}

.back>span {
  position: absolute;
  background-color: transparent;
  font-size: 8rem;
  text-align: right;

  color: transparent;
  -webkit-text-stroke: 2px rgb(35, 35, 35);
  font-weight: 900;
  text-shadow: none;
  z-index: 1;
  top: -20%;
  right: 0;
}

.car, .car>img, .lights, .lights>img {
  width: 100%;
  position: absolute;
  background-color: transparent;
  top: -50%;
  left: 0;
  animation: bounce 20s infinite ease-in-out;
  z-index: 2;
}

.lights {
  opacity: 0;
  transition: 0.5s;
}

.lights:hover {
  opacity: 1;
}

@keyframes rotate{
  to{ transform: rotate(360deg); }
}

@keyframes bounce {
	0%, 20%, 40%, 60%, 80%, 100% {transform: translateY(0);}
  10% {transform: translateY(-15px);}
  30% {transform: translateY(-15px);}
  50% {transform: translateY(-15px);}
  70% {transform: translateY(-15px);}
  90% {transform: translateY(-15px);}
}

@media only screen and (max-width: 768px) {
  .main {
    flex-direction: column-reverse;
    flex-wrap: nowrap;
    padding: 50px 20px;
  } 
  .info, .back {
    flex-basis: 100%;
  }

  .info {
    margin-top: 35vh;
  }

  .back {
    margin-top: -50px;
  }

  .back>span {
    display: none;
  }
}