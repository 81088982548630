.appointment {
  display: flex;
  flex-direction: column;
  padding: 15vh 7.5vw;
  gap: 25px;
  position: relative;
  z-index: 2;
}

.appointment > h2 {
  z-index: 2;
  background-color: transparent;
  font-family: "chakra petch", sans-serif;
  font-size: 42px;
  letter-spacing: 1px;
  -webkit-font-smoothing: antialiased;
}

.appointment > p {
  z-index: 2;
  width: 50%;
  font-size: 18px;
  line-height: 1.444;
  text-size-adjust: 100%;
  unicode-bidi: isolate;
  -webkit-font-smoothing: antialiased;
  font-family: "mulish",sans-serif;
  background-color: transparent;
}

.appointment > form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 25px;
}

.form-element {
  flex-basis: 48%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 25px;
}

.form-element label {
  line-height: 1.444;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0;
}

.form-element input {
  width: 100%;
  outline: none;
  padding: 15px 0;
  border: none;
  color-scheme: rgb(237, 237, 237);
  border-bottom: 1px solid #343434;
  transition: 0.2s ease-in-out;
}

.form-element input:focus {
  border-color: rgb(209, 101, 39);
}

.form-element:last-child {
  flex-basis: 100%;
}

.appointment input::placeholder {
  font-size: 14px;
}

.appointment::before {
  content: "ОБРАТНАЯ СВЯЗЬ";
  position: absolute;
  background-color: transparent;
  font-size: 4rem;

  color: transparent;
  -webkit-text-stroke: 2px rgba(35, 35, 35, 0.8);
  font-weight: 900;
  text-shadow: none;
  z-index: 0;
  top: 12vh;
  margin-left: 2px;
  transition: 0.3s ease-in-out;
}

.appointment:hover::before {
  top: 10vh;
}

@media only screen and (max-width: 768px) {
  .appointment {
    padding: 50px 20px;
  }

  .appointment>h2 {
    font-size: 28px;
  }

  .appointment>p {
    width: 100%;
  }

  .form-element {
    flex-basis: 100%;
  }
}