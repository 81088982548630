.btn {
  font-size: 18px;
  text-decoration: none;
  position: relative;
  display: inline-block;
  text-align: center;
  padding: 10px 30px;
  clip-path: polygon(20px 0, 100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%, 0 20px);
  cursor: pointer;
}

.span {
  z-index: 10;
}

.btn > span {
  background-color: transparent;
}

.transparent > span::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 98%;
  height: 90%;
  border: 2px solid rgb(209, 101, 39);
  transition: 0.3s ease-in-out;
  z-index: -1;
  transform-origin: center;
}

.transparent > span::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  width: 0%;
  height: 120%; 
  background-color: #d16527;
  transition: 0.3s ease-in-out;
  z-index: -1;
}

.transparent:hover > span::before {
  width: 100%;

  inset: 0;
}

.filled {
  padding: 15px 35px;
  background-color: #d16527;
  clip-path: polygon(20px 0, 100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%, 0 20px);
  transition: clip-path 0.3s ease;
  border: 2px solid #d16527;
}

.filled:hover {
  -webkit-clip-path: polygon(0 0, 100% 0,100% calc(100% - 20px),100% 100%,0 100%,0 20px);
  clip-path: polygon(0 0, 100% 0,100% calc(100% - 20px),100% 100%,0 100%,0 20px);
  background-color: transparent;
  border: 2px solid #d16527;
}

.filled::before {
  content: '';
  position: absolute;
  top: 0;
  left: 10%;
  width: 7em;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  transform: translateX(0) skewX(45deg);
  opacity: 0;
}

.filled:hover::before {
  opacity: 1;
  animation: move-light .8s ease-in-out;
  transform: translateX(10em) skewX(45deg);
}

@keyframes move-light {
  from {
    transform: translateX(0) skewX(45deg);
  }
  to {
    transform: translateX(10em) skewX(45deg);
  }
}

