.footer, .footer h3, .footer h2, .footer span, .footer p, .footer div, .footer a {
  background-color: rgb(18, 18, 18);
}

.footer {
  padding: 7.5vh 7.5vw;
  display: flex;
  justify-content: space-between;
}

.footer > div {
  flex-basis: 35%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.footer > div:last-child {
  text-align: right;
}

.footer p {
  font-family: "mulish", sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
}

.footer a {
  font-size: 16px;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  text-decoration: none;
}

h2>span {
  color: rgb(209, 101, 39);
}

@media only screen and (max-width: 768px) {
  .footer {
    padding: 50px 20px;
    flex-wrap: wrap;
    gap: 20px;
  }

  .footer>div {
    flex-basis: 100%;
  }

  .footer>div:last-child {
    text-align: left;
  }
}