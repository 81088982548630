.services, .list, .item {
  display: flex;
}

.services {
  flex-direction: column;
  padding: 15vh 7.5vw;
}

.services>h2 {
  font-family: "chakra petch", sans-serif;
  font-size: 42px;
  letter-spacing: 1px;
  -webkit-font-smoothing: antialiased;
}

.list {
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 7.5vh;
}

.item {
  border-top: 2px solid #242424;
  flex-direction: column;
  flex-basis: 30%;
  gap: 25px;
  padding: 25px 0;
  cursor: pointer;
  position: relative;
}

.item svg {
  font-size: 60px;
}

.item svg>path {
  transition: 0.2s ease-in-out;
}

.item.active svg>path {
  color: rgb(209, 101, 39);
}

.item>h3 {
  font-size: 23px;
  font-family: "chakra petch" ,sans-serif;
  font-weight: 700;
  letter-spacing: normal;
  line-height: 1.43;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

.item>p {
  font-family: "mulish", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.38;
  text-overflow: ellipsis;
  -webkit-font-smoothing: antialiased;
  -webkit-line-clamp: 3;
  -webkit-tap-highlight-color: transparent;
}

.item::before {
  content: "";
  width: 100%;
  border-top: 2px solid rgb(209, 101, 39);
  position: absolute;
  background-color: rgb(209, 101, 39);
  transition: 0.3s ease-in-out;
  scale: 0;
  top: -2px;
}

.item:hover::before {
  scale: 1;
}

@media only screen and (max-width: 768px) {
  .services {
    padding: 50px 20px;
  }
  
  .services>h2 {
    font-size: 28px;
    -webkit-font-smoothing:antialiased;
  }

  .list {
    margin-top: 50px;
  }

  .item {
    flex-basis: 100%;
  }
}