.statistic, .item, .item>span, .item-text>p, .item-text {
  background-color: rgb(18, 18, 18);
}

.statistic {
  display: flex;
  padding: 15vh 7.5vw;
}

.item {
  display: flex;
  flex-basis: 25%;
  flex-direction: column;
  cursor: pointer;
}

.item>span {
  font-size: 66px;
  font-weight: 600;
  padding-bottom: 15px;
}

.item-text {
  position: relative;
  width: 100%;
}

.item-text>p {
  font-family: "Mulish", sans-serif;
  font-size: 16px;
  font-weight: 400;
  border-top: 2px solid #242424;
  padding-top: 15px;
  padding-right: 15px;
  -webkit-font-smoothing: antialiased;
}

.item-text>p::before {
  content: "";
  width: 100%;
  border-top: 2px solid rgb(209, 101, 39);
  position: absolute;
  background-color: rgb(209, 101, 39);
  transition: 0.3s ease-in-out;
  scale: 0;
  top: 0;
}

.item:hover p::before {
  scale: 1;
  width: 100%;
  align-self: center;
}

@media only screen and (max-width: 768px) {
  .statistic {
    padding: 50px 20px;
    flex-wrap: wrap;
  }

  .item {
    flex-basis: 100%;
    margin-bottom: 20px;
  }
}