.header {
  background-color: rgb(22, 22, 22);
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 7.5vw;
  color: rgb(237, 237, 237);
}

.ttl {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
}

.logo > h2,
.logo > h2 > span {
  font-size: 26px;
  cursor: pointer;
}

.logo > h2 > span {
  color: rgb(209, 101, 40);
}

.nav-item {
  font-size: 18px;
  color: rgb(237, 237, 237);
  text-decoration: none;
  padding: 10px;
  background-image: linear-gradient(rgb(209, 101, 39), rgb(209, 101, 39)),
    linear-gradient(rgb(22, 22, 22), rgb(22, 22, 22));
  background-size: 0 2px, auto;
  background-repeat: no-repeat;
  background-position: center bottom;
  transition: all 0.2s ease-out;
}

.nav-item:hover {
  background-size: 100% 2px, auto;
  color: rgb(209, 101, 39);
}

.header > nav > ul {
  list-style: none;
  display: flex;
  gap: 2vw;
  font-size: 22px;
}

.actions {
  display: flex;
  align-items: center;
  gap: 15px;
}


@media only screen and (max-width: 768px) {
  nav {
    display: none;
  }
}