.howto,
.heading,
.list,
.item {
  display: flex;
  background-color: rgb(18, 18, 18);
}

.item>h3, .item>h3>span, .item>p, .heading > h2, .heading > p {
  background-color: rgb(18, 18, 18);
}

.howto {
  padding: 15vh 7.5vw;
  padding-bottom: calc(7.5vw - 50px);
  flex-wrap: wrap;
  justify-content: space-between;
}

.heading {
  flex-basis: 35%;
  flex-direction: column;
  gap: 25px;
}

.heading > h2 {
  font-family: "chakra petch", sans-serif;
  font-size: 42px;
  letter-spacing: 1px;
  -webkit-font-smoothing: antialiased;
}

.heading > p {
  font-size: 18px;
  line-height: 1.444;
  text-size-adjust: 100%;
  unicode-bidi: isolate;
  -webkit-font-smoothing: antialiased;
  font-family: "mulish",sans-serif;
}

.list {
  flex-basis: 60%;
  flex-wrap: wrap;
  justify-content: space-between;
}

.item {
  flex-direction: column;
  flex-basis: 48%;
  gap: 25px;
  font-family: "mulish",sans-serif;
  border-top: 2px solid #242424;
  padding-top: 20px;
  position: relative;
  margin-bottom: 50px;
}

.item>h3>span {
  color: rgb(209, 101, 39);
  font-size: larger;
  margin-right: 10px;
}

.item>p {
  font-size: 16px;
  line-height: 22px;
  -webkit-font-smoothing: antialiased;
}

.item::before {
  content: "";
  width: 100%;
  border-top: 2px solid rgb(209, 101, 39);
  position: absolute;
  background-color: rgb(209, 101, 39);
  transition: 0.3s ease-in-out;
  scale: 0;
  top: -2px;
}

.item:hover::before {
  scale: 1;
}

@media only screen and (max-width: 768px) {
  .howto {
    padding: 50px 20px;
  }

  .heading {
    flex-basis: 100%;
  }

  .heading > h2 {
    font-size: 28px;
  }

  .list {
    margin-top: 50px;
    flex-basis: 100%;
  }

  .item {
    flex-basis: 100%;
    margin-bottom: 25px;
  }
}