@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: "Chakra Petch", sans-serif;
  color: rgb(237, 237, 237);
  background-color: rgb(22, 22, 22);
}

h2 {
  line-height: 1.2;
  letter-spacing: normal;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

.body {
  overflow-x: hidden;
}

.loader {
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}

.loader .disc-ring {
  color: rgb(209, 101, 39);
  border-width: 0.60em!important;

}